import { Bank, BankType } from "@/types/bank";
import { Currency } from "@/types/currency";
import { CardType } from "@/types/card";

const mockBanks: Bank[] = [
  {
    id: 0,
    name: "TEST (USD)",
    currency: Currency.USD,
    country: "US",
    bankType: BankType.DUMMY,
    cardIssueCost: 5,
    cardTypes: [CardType.POSTPAID, CardType.PREPAID],
    isEnabled: true,
    hidden: false,
    allowedIssueCard: true,
    depositFee: 0,
    transactionFee: 0,
    fixedFee: 0,
    bankExchangeFeeMult: 2,
    fixedFeeThreshold: 0,
    info: {
      zipInfo: "3751 Thompson Street, Santa Fe Springs, 90670, US",
      "3ds": false,
      binId: "0000",
      paymentSystem: "VISA",
      recommendations: {
        google: true,
        facebook: true,
        tikTok: true,
        seo: true,
        ai: true,
        hosting: true,
        taboola: true,
      },
    },
  },
];

export default mockBanks;
