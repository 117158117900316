import { Component, Mixins } from "vue-property-decorator";

import BanksListMixin from "../../../common/mixins/banks-list.mixin";
import BankTile from "./components/BankTile/BankTile.vue";

@Component({
  components: {
    BankTile,
  },
})
export default class BanksList extends Mixins(BanksListMixin) {}
