import { Component, Vue, Prop } from "vue-property-decorator";
import debounce from "lodash.debounce";
import { getFlagIconUrl } from "@helpers";
import { bankModule, userModule } from "@store/namespaces";
import { BankActions, BankGetters } from "@store/modules/bank/types";
import { CardType } from "@/types/card";
import { getCardIconSrc } from "@/lib/creaditCard";
import { Bank } from "@/types/bank";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";
import { HtmlElementId, HtmlElementClassName } from "@/types/element";
import mockBanks from "@/mockData/banks";

interface BankItem extends Bank {
  bankGroupName: string;
  bankGroupDisplayOrder: number;
  favorite: boolean;
}

@Component
export default class BanksListMixin extends Vue {
  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];

  @bankModule.Action("fetchUserBanks")
  protected readonly fetchUserBanksAction!: BankActions["fetchUserBanks"];
  @bankModule.Getter("loadingUserBanks")
  protected readonly loadingUserBanksGetter!: BankGetters["loadingUserBanks"];
  @bankModule.Getter("userBanks")
  private readonly userBanksGetter!: BankGetters["userBanks"];

  @Prop({
    type: String,
    required: true,
  })
  private readonly cardType!: CardType;

  private favoriteBanks: number[] = [];
  private loading = false;

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  protected get loadingList(): boolean {
    return (
      !this.isShowedMainIntro && (this.loading || this.loadingUserBanksGetter)
    );
  }

  private get htmlElementId() {
    return {
      issueCardBanksList: HtmlElementId.issueCardBanksList,
    };
  }

  private get htmlElementClassName() {
    return {
      selectBankIssueCard: HtmlElementClassName.selectBankIssueCard,
    };
  }

  private get availableItems() {
    if (this.isShowedMainIntro) {
      return mockBanks;
    }

    return this.listItems;
  }

  private get listItems() {
    const cardType = this.cardType;
    const favoriteBanks = this.favoriteBanks;

    return this.userBanksGetter.reduce<BankItem[]>((banks, bank) => {
      if (!bank.isEnabled) {
        return banks;
      }

      if (!bank.allowedIssueCard && bank.hidden) {
        return banks;
      }

      if (bank.cardTypes.length && !bank.cardTypes.includes(cardType)) {
        return banks;
      }

      const bankGroupCountries =
        bank.bankGroup?.country.split(",").map((code) => {
          return {
            code,
            flagUrl: this.getFlagIconUrl(code),
            name: this.$vuetify.lang.t(
              `$vuetify.dictionary.country.${code.toLowerCase()}`
            ),
          };
        }) || [];

      const bankGroupDisplayOrder = bank.bankGroup?.displayOrder ?? -1;

      banks.push(
        Object.freeze({
          ...bank,
          favorite: favoriteBanks.includes(bank.id),
          bankGroupName: bank.bankGroup?.name || "No Group",
          bankGroupDisplayOrder,
          bankGroupCountries,
        })
      );

      return banks;
    }, []);
  }

  private getBankRecommendations(item: Bank) {
    return [
      {
        name: "google",
        iconSrc: require("@/assets/img/icons/google.svg"),
        enabled: item.info.recommendations.google,
      },
      {
        name: "tiktok",
        iconSrc: require("@/assets/img/icons/tiktok.svg"),
        enabled: item.info.recommendations.tikTok,
      },
      {
        name: "facebook",
        iconSrc: require("@/assets/img/icons/facebook.svg"),
        enabled: item.info.recommendations.facebook,
      },
      {
        name: "seo",
        iconSrc: require("@/assets/img/icons/seo.svg"),
        enabled: item.info.recommendations.seo,
      },
      {
        name: "ai",
        iconSrc: require("@/assets/img/icons/ai.svg"),
        enabled: item.info.recommendations.ai,
      },
      {
        name: "hosting",
        iconSrc: require("@/assets/img/icons/hosting.svg"),
        enabled: item.info.recommendations.hosting,
      },
      {
        name: "taboola",
        iconSrc: require("@/assets/img/icons/taboola.svg"),
        enabled: item.info.recommendations.taboola,
      },
    ].filter(({ enabled }) => enabled);
  }

  protected getCardIconSrc(type: string): string {
    return getCardIconSrc(type);
  }

  private getFlagIconUrl(countryCode: string): string {
    return getFlagIconUrl(countryCode);
  }

  private onSelectBank(bank: Bank) {
    this.$emit("select:bank", bank);
  }

  private checkAllowIssueCard(bank: Bank) {
    return bank.allowedIssueCard;
  }

  private checkBankInFavorites(
    bankId: number,
    favoriteBanks = this.favoriteBanks
  ) {
    return favoriteBanks.includes(bankId);
  }

  private toggleFavoriteBank(bankId: number) {
    const favoriteBankIndex = this.favoriteBanks.findIndex(
      (favoriteBankId) => favoriteBankId === bankId
    );

    if (favoriteBankIndex === -1) {
      this.favoriteBanks.push(bankId);
    } else {
      this.favoriteBanks.splice(favoriteBankIndex, 1);
    }
  }

  private async initWebPreferences() {
    this.loading = true;

    try {
      await this.fetchUserWebPreferencesAction({
        key: UserWebPreferencesKey.FAVORITE_BANKS_ISSUE_CARD,
      });

      const { value } = this.userWebPreferencesGetter({
        key: UserWebPreferencesKey.FAVORITE_BANKS_ISSUE_CARD,
      });

      const userWebPreferences = value as Partial<{ favorites: number[] }>;

      this.favoriteBanks = Array.isArray(userWebPreferences.favorites)
        ? userWebPreferences.favorites
        : [];

      return this.$watch(
        () => {
          return this.favoriteBanks.length;
        },
        debounce(() => {
          this.updateUserWebPreferencesAction({
            key: UserWebPreferencesKey.FAVORITE_BANKS_ISSUE_CARD,
            value: {
              favorites: this.favoriteBanks,
            },
          });
        }, 500)
      );
    } finally {
      this.loading = false;
    }
  }

  protected created(): void {
    this.fetchUserBanksAction();
    this.initWebPreferences();
  }
}
